import styled, { css } from 'styled-components'
import { theme } from '../../theme/theme'

export const MainContent = styled.div`
    flex-grow: 1;
`

export const LayoutContainer = styled.div<{isLoggedIn: boolean, isNavCollapsed: boolean}>(
  ({ isLoggedIn, isNavCollapsed }) => css`
    display: flex;
    justify-content: space-between;

    ${MainContent} {
        padding: ${isLoggedIn ? `${!isNavCollapsed ? `0 ${theme.space.md} ${theme.space.lg} 271px` : `0 ${theme.space.md} ${theme.space.lg} 123px`}` : '0'};
        transition: all 0.2s ease-in;

        ${theme.mediaQueries.mdMax} {
            padding: 0 ${theme.space.md} ${theme.space.md};
        }
    }
`,
)
