import React, { useContext } from 'react'
import { createGlobalStyle } from 'styled-components'
import { GlobalStateContext } from '../../providers/GlobalStateProvider'
import { LayoutContainer } from './Layout.styles'

export const Layout: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const {
    userAccess, isNavCollapsed, slideoutOpen,
  } = useContext(GlobalStateContext)

  const GlobalStyle = createGlobalStyle`
  body {
    overflow: ${slideoutOpen ? 'hidden' : 'auto'};
  }
`

  return (
    <>
      <GlobalStyle />
      <LayoutContainer
        isLoggedIn={userAccess !== 0}
        isNavCollapsed={isNavCollapsed}
      >{children}
      </LayoutContainer>
    </>
  )
}
