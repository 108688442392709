import styled, { css } from 'styled-components'
import { theme } from '../../theme/theme'

interface StyledMenuProps {
  open: boolean
  isNavCollapsed: boolean
}

export const StyledMenu = styled.nav<StyledMenuProps>(
  ({ open, isNavCollapsed }) => css`
  background: radial-gradient(75.65% 75.65% at 50% -2.19%,#006B7E 0%,#004D5F 100%);
  position: fixed;
  top: 0;
  overflow: auto;
  height: 100%;
  color: #FFF;
  text-align: left;
  padding: 0 ${theme.space.md};
  min-width: 220px;
  box-sizing: content-box;
  border-right: 3px dotted ${theme.colors.blueUI};
  z-index: 999;
  transition: all 0.2s ease-in;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #003F4E;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 6px;
  }

  ${theme.mediaQueries.xl} {
    padding: 0 ${theme.space.md};
    
    }
  

  ${theme.mediaQueries.mdMax} {
    position: absolute;
    z-index: 99;
    top: 56px;
    left: 0;
    bottom: 0;
    height: auto;
    width: 100%;
    border: none;
    transition: all 0.3s ease-in-out;
    transform: ${open ? 'translateY(0)' : 'translateY(-80px)'};
    opacity: ${open ? '1' : '0'};
    pointer-events: ${open ? 'auto' : 'none'};
    margin: 0;
    padding: ${theme.space.md};
    background: ${theme.colors.blueBGUI};
    box-sizing: border-box;
  }

  ${theme.mediaQueries.md} {
    ${isNavCollapsed && `
      min-width: 72px;

      ul button { 
        justify-content: center !important;

        & > svg {
          margin: 0 !important;
        }
      } 

      .menuText {
        display: none;
      }
    `}
  }

  .logo {
    display: block;
    // plus 3 pixels for visual alignment (bad practice)
    margin: calc(${theme.space.lg} + 5px) auto;
    ${isNavCollapsed && `margin: calc(${theme.space.lg} - 3px) auto;`}
    //width: ${isNavCollapsed ? '60px' : '220px'};
    max-width: 220px;

    ${theme.mediaQueries.mdMax} {
      display: none;
    }
  }

  ul button {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: rgba(6, 192, 226, 0.14);
    color: #FFF;
    font-weight: bold;
    box-shadow: none;
    text-transform: none;

    & > svg {
      margin-right: ${theme.space.sm};
      width: 26px;
      height: 26px;
    }

    .menuText {
      transform: translate(0px, 2px);
      flex-grow: 1;
    }

    &:hover {
      background: rgba(6, 192, 226, 0.44);
      box-shadow: 0px 3px 3px rgb(0 0 0 / 10%)
    }

    &.active {
      background: #FFF;
      color: ${theme.colors.orangeUI};
    }

    &.MuiLoadingButton-root.Mui-disabled {
      background: rgba(6, 192, 226, 0.14) !important;
      color: #FFF;

      & .MuiLoadingButton-loadingIndicator {
        display: inline-block;
      }
    }
  }

  .MuiCollapse-root {
    padding-top: ${theme.space.sm};

    .MuiListItemButton-root {
      border-radius: 5px;

      &.active {
        background: #FFF;
        color: ${theme.colors.orangeUI};

        .css-nodkat {
          background-color: ${theme.colors.orangeUI};
        }
      }
    }
  }

  .css-nodkat {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: white;
    margin: 0 ${theme.space.sm} 0 0;
    transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

  h3 {
    margin: ${theme.space.lg} 0 ${theme.space.md} ${theme.space.sm};
    font-weight: normal;
    font-size: ${theme.fontSizes.md};
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0 0 ${theme.space.sm};
  }

  a {
    display: block;
    background: rgba(6, 192, 226, 0.14);
    padding: ${theme.space.sm};
    font-size: 16px;
    color: #FFF;
    text-decoration: none;
    border-radius: 10px;
  }
    `,
)

export const SlideoutCloseButton = styled.span`
  font-size: 36px;
  cursor: pointer;
`

export const CollapseMenuButton = styled.button`
  position: absolute;
  top: 10px;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 50% 0 0 50%;
  background: rgba(6, 192, 226, 0.14);
  border: none;
  color: #FFF;
  text-align: center;
  padding: 0;
  cursor: pointer;

  ${theme.mediaQueries.mdMax} {
    display: none;
  }
`
