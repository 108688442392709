import React, {
  Dispatch, createContext, useState, SetStateAction,
} from 'react'

import * as ls from 'local-storage'

interface UserInfoProps {
  name?: string
  access?: number
  email?: string
  id?: number,
  companyName?: string
  telephone?: string,
  headquaters?: string
  totalCredits?: string
}

interface GlobalContextProps {
  slideoutOpen: boolean,
  setSlideoutOpen: Dispatch<SetStateAction<boolean>>,
  isNavCollapsed: boolean,
  setIsNavCollapsed: Dispatch<SetStateAction<boolean>>,
  userAccess: number,
  setUserAccess: Dispatch<SetStateAction<number>>,
  userInfo: UserInfoProps
  setUserInfo: Dispatch<SetStateAction<UserInfoProps>>,
  permissionsList: {},
  isLoading: boolean,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  authError: boolean,
  setAuthError: Dispatch<SetStateAction<boolean>>,
  resultsLoading: boolean,
  setResultsLoading: Dispatch<SetStateAction<boolean>>,
  filteredDataParams: {[key: string]: any},
  setFilteredDataParams: Dispatch<SetStateAction<any>>,
}

export const GlobalStateContext = createContext<GlobalContextProps>({
  slideoutOpen: false,
  setSlideoutOpen: () => {},
  isNavCollapsed: false,
  setIsNavCollapsed: () => {},
  userAccess: 0,
  setUserAccess: () => {},
  userInfo: {},
  setUserInfo: () => {},
  permissionsList: {},
  isLoading: false,
  setIsLoading: () => {},
  authError: false,
  setAuthError: () => {},
  resultsLoading: false,
  setResultsLoading: () => {},
  filteredDataParams: {},
  setFilteredDataParams: () => {},
})

const permissionsList = {
  0: 'search',
  1: 'service',
  2: 'archive',
  3: 'storage',
}

export const GlobalStateProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [slideoutOpen, setSlideoutOpen] = useState<boolean>(false)
  const [isNavCollapsed, setIsNavCollapsed] = useState<boolean>(false)
  const initialUserAccess: number = ls.get('userAccess') !== null ? ls.get('userAccess') : 0
  const [userAccess, setUserAccess] = useState<number>(initialUserAccess)
  const [userInfo, setUserInfo] = useState(ls.get('userInfo') !== null ? ls.get('userInfo') : {})
  const [isLoading, setIsLoading] = useState(false)
  const [authError, setAuthError] = useState(false)
  const [resultsLoading, setResultsLoading] = useState(false)
  const [filteredDataParams, setFilteredDataParams] = useState({})

  return (
    <GlobalStateContext.Provider value={{
      slideoutOpen,
      setSlideoutOpen,
      isNavCollapsed,
      setIsNavCollapsed,
      userAccess,
      setUserAccess,
      userInfo,
      setUserInfo,
      permissionsList,
      isLoading,
      setIsLoading,
      authError,
      setAuthError,
      resultsLoading,
      setResultsLoading,
      filteredDataParams,
      setFilteredDataParams,
    }}
    >
      {children}
    </GlobalStateContext.Provider>
  )
}
