import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState, useRef, memo,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import {
  Marker, Source, Layer, Cluster, GeoJSONLayer,
} from 'react-mapbox-gl'
import centroid from '@turf/centroid'

import { polygon } from '@turf/helpers'
import { Map, StaticMap } from '../../utils/mapInstance'
import { MapContainer } from '../FieldsListing/FieldsListing.styles'
import { calcBoundsFromCoordinates } from '../../utils/calcBounds'
import { FieldItem } from '../FieldsListing/Fields.types'
import flag from '../../icons/flag.svg'
import { ToggleFieldFill } from './ToggleFieldFill'
import { MapOverlay } from './MapOverlay/MapOverlay'
import { getAllCoordinates } from '../../utils/getAllCoordsFromMap'
import { theme } from '../../theme/theme'
import { ZoneField } from '../ZonesListing/ZonesListing.types'
import { MapDataContext } from '../../providers/MapDataProvider'
import { ndviColorRange } from '../../utils/ndviColorRange'
import { hexToRgb, contrastChecker } from '../../utils/contrastChecker'

interface FieldMapProps {
  fields?: FieldItem[]
  clusterPoints?: any
  mapOverlayType?: 'full' | 'withZones'
  zonedFields?: ZoneField[] | undefined
  allGeojsonFieldsWithoutAZone?: any
  zoneColor?: string
  ndviData?: object
  expectNdviData?: boolean
  staticMap?: boolean
  clickableFields?: boolean
  allowSetPoint?: boolean
  displayPoint?: boolean
  flagsList?: []
  containerMargin?: boolean
  eixstingPoint?: number[]
  setSelectedPoint?: Dispatch<SetStateAction<number[] | undefined>>
  height?: string
}

export const FieldMap: React.FC<FieldMapProps> = (
  {
    fields,
    clusterPoints,
    mapOverlayType,
    zonedFields,
    allGeojsonFieldsWithoutAZone,
    zoneColor,
    ndviData,
    expectNdviData = false,
    staticMap = false,
    clickableFields = false,
    allowSetPoint = false,
    displayPoint = false,
    flagsList = [],
    containerMargin = true,
    setSelectedPoint,
    eixstingPoint,
    height,
  },
) => {
  const {
    allAccountFields,
    fieldFill,
    zoneVisibility,
    setZoneVisibility,
    nonZonedFieldsVisibility,
    setAllVisibleFieldIds,
    dataLayerContent,
    cashCropVisibilityFilter,
  } = useContext(MapDataContext)
  const [center, setCenter] = useState<[number, number]>([25.181873, 42.229602])
  const [fieldCentroid, setFieldCentroid] = useState<number[]>()
  const [customPoint, setCustomPoint] = useState<number[] | undefined>(eixstingPoint || undefined)
  const [zoom] = useState<[number]>([8])
  const [geojsonFields, setGeojsonFields] = useState<any>([])
  const [showIndividualMarkers, setShowIndividualMarkers] = useState<boolean>(true)
  const [isMapExpanded, setIsMapExpanded] = useState<boolean>(false)
  const history = useHistory()
  const firstRunRecenterMapCheck = useRef(true)
  const MapRef = useRef<any>(null)
  const { t } = useTranslation()

  useEffect(() => {
    if (!fields) return

    // when fields change, recenter map (used in zone fields management)
    if (firstRunRecenterMapCheck.current) {
      firstRunRecenterMapCheck.current = false
    } else if (fields.length > 0) {
      setTimeout(() => {
        MapRef.current?.state.map.fitBounds(
          calcBoundsFromCoordinates(getAllCoordinates(MapRef)), { padding: 50 },
        )
      }, 100)
    }

    const geoJsonData = fields.map((item:any) => {
      const data = JSON.parse(item.coordinates)
      const returnData = {
        ...data,
        fieldId: item.id,
        id: item.id,
      }
      return returnData
    })

    if (geoJsonData.length === 1) {
      if (geoJsonData[0].geometry.coordinates[0][0][0][0]) {
        setCenter(
          [geoJsonData[0].geometry.coordinates[0][0][0][0],
            geoJsonData[0].geometry.coordinates[0][0][0][1]],
        )
        const polygonFeature = polygon([geoJsonData[0].geometry.coordinates[0][0]])
        const centroidFeature = centroid(polygonFeature)
        setFieldCentroid(centroidFeature.geometry.coordinates)
      } else {
        setCenter(
          [geoJsonData[0].geometry.coordinates[0][0][0],
            geoJsonData[0].geometry.coordinates[0][0][1]],
        )
        const polygonFeature = polygon([geoJsonData[0].geometry.coordinates[0]])
        const centroidFeature = centroid(polygonFeature)
        setFieldCentroid(centroidFeature.geometry.coordinates)
      }
    }
    setGeojsonFields(geoJsonData)
  }, [JSON.stringify(fields)])

  useEffect(() => {
    if (setSelectedPoint) {
      setSelectedPoint(fieldCentroid)
    }
  }, [fieldCentroid])

  useEffect(() => {
    if (!zonedFields) return
    // set initial array for zone visibility
    const zoneVisibilityTemp = zonedFields.map((item: any) => (
      {
        visible: true, id: item.zoneId, colour: item.colour, fields: item.fields, name: item.name,
      }
    ))
    setZoneVisibility(zoneVisibilityTemp)
  }, [zonedFields])

  useEffect(() => {
    // get IDs for all visible fields when field filters change
    let allVisibleFieldsIds:Array<number> = []

    if (zoneVisibility && zoneVisibility.length !== 0) {
      // get IDs for all visible fields in zones
      const visibleZones = zoneVisibility.filter((item:any) => item.visible)
      const fieldsInVisibleZones = visibleZones.map((item:any) => item.fields)
      const fieldsInVisibleZonesFlat = fieldsInVisibleZones.flat(Infinity)
      const visibleFieldsInZonesIds = fieldsInVisibleZonesFlat.map((item:any) => item.id)
      allVisibleFieldsIds.push(...visibleFieldsInZonesIds)
    }

    if (allGeojsonFieldsWithoutAZone) {
      // get IDs for all visible fields not in a zone
      const allGeojsonFieldsWithoutAZoneIds = allGeojsonFieldsWithoutAZone.map(
        (item:any) => item.fieldId,
      )

      if (nonZonedFieldsVisibility) {
        allVisibleFieldsIds.push(...allGeojsonFieldsWithoutAZoneIds)
      }
    }

    if (cashCropVisibilityFilter !== null) {
      // get IDs for all visible fields with a certain cash crop
      const visibleFieldsWithCashCrop = allAccountFields.filter(
        (item:FieldItem) => item.cashCrop?.id === cashCropVisibilityFilter,
      )
      const visibleFieldsWithCashCropIds = visibleFieldsWithCashCrop.map(
        (item:FieldItem) => item.id,
      )

      const visibleFieldsWithCashCropInVisibleZones = visibleFieldsWithCashCropIds.filter(
        (item:number) => allVisibleFieldsIds.includes(item),
      )

      allVisibleFieldsIds = visibleFieldsWithCashCropInVisibleZones
    }

    setAllVisibleFieldIds(allVisibleFieldsIds)
  }, [zoneVisibility, nonZonedFieldsVisibility, cashCropVisibilityFilter])

  const MapInstance = staticMap ? StaticMap : Map

  const styles: { [key: string]: React.CSSProperties } = {
    clusterMarker: {
      width: 40,
      height: 40,
      borderRadius: '50%',
      backgroundColor: theme.colors.orangeUI,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#000',
      border: `2px solid ${theme.colors.blueUI}`,
      cursor: 'pointer',
    },
    marker: {
      width: 40,
      height: 40,
      borderRadius: '50%',
      backgroundColor: theme.colors.orangeUI,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: `2px solid ${theme.colors.blueUI}`,
      color: '#000',
      cursor: 'pointer',
    },
    infoBox: {
      width: 80,
      height: 70,
      padding: '5px',
      borderRadius: '5px',
      backgroundColor: '#FFF',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#000',
      lineHeight: '1.1em',
      textAlign: 'center',
    },
    infoBoxTitle: {
      display: 'block',
      marginBottom: '5px',
    },
  }

  const clusterMarker = (
    coordinates: any,
    pointCount: number,
  ) => (
    <Marker
      key={coordinates.toString()}
      coordinates={coordinates}
      style={styles.clusterMarker}
    >
      <div>{pointCount}</div>
    </Marker>
  )

  const getInfoBoxColors = (fieldData: any) => {
    let backgroundColor = '#FFF'
    let textColor = '#000'

    if (fieldData?.data && fieldData?.isColorCoded) {
      if (fieldData.type === 'ndvi') {
        // @ts-ignore
        backgroundColor = theme.colors.ndviColors[ndviColorRange(fieldData.data)]
      } else if (fieldData.type === 'soil') {
        // @ts-ignore
        backgroundColor = theme.colors.progressColors[Math.round(fieldData.data) - 1]
      }
      // @ts-ignore
      const backgroundColorRGBObject = hexToRgb(backgroundColor)
      const backgroundColorRGB = Object.keys(backgroundColorRGBObject!).map(
        // @ts-ignore
        (item: string) => backgroundColorRGBObject![item],
      )
      const contrastRatio = contrastChecker([255, 255, 255], backgroundColorRGB)
      if (contrastRatio > 4.5) {
        textColor = '#FFF'
      }
    }

    return { backgroundColor, textColor }
  }

  const getMarkerStyles = (fieldId: number) => {
    if (!showIndividualMarkers && dataLayerContent && dataLayerContent.length > 0) {
      const fieldData = dataLayerContent.find((item: any) => item.fieldId === fieldId)
      const { backgroundColor, textColor } = getInfoBoxColors(fieldData)

      return { ...styles.infoBox, backgroundColor, color: textColor }
    }

    return { ...styles.marker, display: showIndividualMarkers ? 'flex' : 'none' }
  }

  const renderMarkerContent = (fieldId: any) => {
    const fieldData = dataLayerContent.find((item: any) => item.fieldId === fieldId)

    // render data layer content if it exists
    if (!showIndividualMarkers && fieldData) {
      return (
        <>
          <strong style={styles.infoBoxTitle}>{fieldData.title}</strong>
          {fieldData.data ?? t('mapOverlay.noData')}
        </>
      )
    }

    // render '1' to display in a regular marker if no data layer content exists
    return '1'
  }

  const renderFiltersOverylay = (type: 'full' | 'withZones' | undefined) => {
    switch (type) {
      case 'full':
        return <MapOverlay />
      case 'withZones':
        return (
          <ToggleFieldFill
            isMapExpanded={isMapExpanded}
            setIsMapExpanded={setIsMapExpanded}
            mapReference={MapRef}
            withZones
          />
        )
      default:
        return (
          <ToggleFieldFill
            isMapExpanded={isMapExpanded}
            setIsMapExpanded={setIsMapExpanded}
            mapReference={MapRef}
          />
        )
    }
  }

  const staticMapHeight = height || '350px'
  const headingContainer = document.getElementById('pageHeadingContainer')
  const headingContainerOffsetHeight = headingContainer ? headingContainer.offsetHeight : 0
  // minus 15 for visual padding and map borders, 16 for heading container margin
  const expandedHeight = window.innerHeight - headingContainerOffsetHeight - 16 - 15

  if ((!zonedFields && geojsonFields.length === 0)
     || (zonedFields && zonedFields.length === 0 && !mapOverlayType)) {
    return null
  }

  return (

    <MapContainer containerMargin={containerMargin}>
      {!expectNdviData && (
        renderFiltersOverylay(mapOverlayType)
      )}
      <MapInstance
        // eslint-disable-next-line react/style-prop-object
        style="mapbox://styles/mapbox/satellite-streets-v11"
        containerStyle={{ height: isMapExpanded ? expandedHeight : staticMapHeight, width: '100%', minHeight: '200px' }}
        zoom={zoom}
        center={center}
        ref={MapRef}
        onZoomEnd={() => {
          if (MapRef.current.state.map.getZoom() < 10) {
            setShowIndividualMarkers(true)
          } else {
            setShowIndividualMarkers(false)
          }
        }}
        onStyleLoad={(map) => {
          if (clickableFields) {
            map.on('click', (e:any) => {
              const selectedFeatures = map.queryRenderedFeatures(e.point, { layers: ['fields-fill'] })
              history.push(`/fieldDetails/${selectedFeatures[0].id}`)
            })

            // Change the cursor to a pointer when the mouse is over a fields layer
            let fieldHoverThrottle: boolean = false
            map.on('mousemove', (e:any) => {
              if (fieldHoverThrottle) return
              fieldHoverThrottle = true
              const selectedFeatures = map.queryRenderedFeatures(e.point, { layers: ['fields-fill'] })
              if (selectedFeatures.length > 0) {
                // eslint-disable-next-line no-param-reassign
                map.getCanvas().style.cursor = 'pointer'
              } else {
                // eslint-disable-next-line no-param-reassign
                map.getCanvas().style.cursor = ''
              }
              setTimeout(() => {
                fieldHoverThrottle = false
              }, 100)
            })
          }

          if (allowSetPoint) {
            map.on('click', (e: any) => {
              setCustomPoint([e.lngLat.lng, e.lngLat.lat])
              if (setSelectedPoint) {
                setSelectedPoint([e.lngLat.lng, e.lngLat.lat])
              }
            })
          }

          MapRef.current?.state.map.fitBounds(
            calcBoundsFromCoordinates(getAllCoordinates(MapRef, !!mapOverlayType, mapOverlayType === 'full')), { padding: 50 },
          )
        }}
      >
        {zonedFields && zonedFields.length > 0 && zoneVisibility && zoneVisibility.length > 0 ? (
          <>
            {/* The order of GeoJSONLayers is important, as it is referenced by its placement
             in the children array when getting coordinates in getAllCoordsFromMap.tsx */}

            {zonedFields.length > 0 ? zonedFields.map((zoneItem: any, key: number) => {
              // render individual geoJsonLayers for each zone's fields
              const zoneGeojsonFields = zoneItem.fields.map((fieldItem:any) => {
                const data = JSON.parse(fieldItem.coordinates)
                const returnData = {
                  ...data,
                  fieldId: fieldItem.id,
                  id: fieldItem.id,
                }
                return returnData
              })

              return (
                <GeoJSONLayer
                  linePaint={{
                    'line-width': 2,
                    'line-color': zoneItem.colour,
                    'line-opacity': zoneVisibility[key].visible ? 0.5 : 0,
                  }}
                  fillPaint={{
                    'fill-color': zoneItem.colour,
                    'fill-opacity': !fieldFill || !zoneVisibility[key].visible ? 0 : 0.5,
                  }}
                  data={{
                    type: 'FeatureCollection',
                    features: zoneGeojsonFields,
                  }}
                  key={`zone-${key}`}
                />
              )
            }) : null}

            <GeoJSONLayer
              // render a layer for fields that are not in a zone
              linePaint={{
                'line-width': 2,
                'line-color': '#FF0',
                'line-opacity': nonZonedFieldsVisibility ? 0.5 : 0,
              }}
              fillPaint={{
                'fill-color': '#FF0',
                'fill-opacity': !nonZonedFieldsVisibility || !fieldFill ? 0 : 0.5,
              }}
              data={{
                type: 'FeatureCollection',
                features: allGeojsonFieldsWithoutAZone,
              }}
              id="fieldsWithoutZone"
            />
          </>
        ) : (
          <GeoJSONLayer
            // render regular fields layer
            linePaint={{
              'line-width': 2,
              'line-color': zoneColor || '#FF0',
              'line-opacity': 1,
            }}
            fillPaint={{
              'fill-color': zoneColor || '#FF0',
              'fill-opacity': fieldFill ? 0.5 : 0,
            }}
            data={{
              type: 'FeatureCollection',
              features: geojsonFields,
            }}
            id="fields"
          />
        )}
        <>
          {expectNdviData && (
            <>
              <Source id="ndvi_source" tileJsonSource={ndviData} style={{ display: ndviData ? 'block' : 'none' }} />
              <Layer
                type="raster"
                id="ndvi_layer_id"
                sourceId="ndvi_source"
                paint={{
                  'raster-opacity': 1,
                }}
                style={{ display: ndviData ? 'block' : 'none' }}
              />
            </>
          )}
        </>
        {(customPoint || fieldCentroid) && (
          <Marker
            coordinates={customPoint || fieldCentroid}
            anchor="bottom"
            style={{ display: displayPoint ? 'block' : 'none' }}
          >
            <img
              style={{
                width: '20px', height: '20px', transform: 'translate(5px, 4px) scale(1)',
              }}
              src={flag}
              alt=""
            />
          </Marker>
        )}
        <>
          {flagsList.length > 0 && flagsList.map((item: any) => (
            <Marker
              coordinates={item.coordinates}
              anchor="bottom"
              key={item.id}
              onClick={() => {
                history.push(`/SoilAnalysisDetails/${item.id}`)
              }}
              style={{ cursor: 'pointer' }}
            >
              <img
                style={{
                  width: '20px', height: '20px', transform: 'translate(5px, 4px)', transition: 'all 0.3s ease',
                }}
                src={flag}
                alt=""
                id={`flag_${item.id}`}
              />
            </Marker>
          ))}
        </>
        {clusterPoints && clusterPoints.length > 0 && (
          <Cluster ClusterMarkerFactory={clusterMarker} zoomOnClick>
            {clusterPoints.map((feature: any, key: number) => (

              <Marker
                key={key}
                style={getMarkerStyles(feature.fieldId)}
                coordinates={feature.geometry.coordinates}
                data-feature={feature}
                onClick={() => {
                  if (!feature.fieldCoordinates) return
                  MapRef.current?.state.map.fitBounds(
                    calcBoundsFromCoordinates(feature.fieldCoordinates), { padding: 50 },
                  )
                }}
              >
                <div style={{ userSelect: 'none' }}>{renderMarkerContent(feature.fieldId)}</div>
              </Marker>

            ))}
          </Cluster>
        )}
      </MapInstance>
    </MapContainer>
  )
}

// eslint-disable-next-line import/no-default-export
export default memo(FieldMap)
