import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { theme } from './theme/theme'
import { FieldItemRow } from './components/FieldsListing/FieldsListing.styles'

interface LoginButtonProps {
    variant: 'blue' | 'white'
}

export const PageContent = styled.div`
    flex-grow: 1
`

export const WelcomeContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100vh;
    justify-content: space-between;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: calc(100vh / 2 + 65px);
        left: 157px;
        width: calc(100% - 157px);
        height: 3px;
        background: #C4C4C4;
    }

    ${theme.mediaQueries.lgMax} {
        text-align: center;
        flex-direction: column;
        justify-content: space-evenly;

        &::after {
            display: none;
        }
    }
`

export const LogoContainer = styled.div`
    ${theme.mediaQueries.lgMax} {
        border-bottom: 3px solid #C4C4C4;
        padding-bottom: ${theme.space.lg};
    }
`

export const SloganText = styled.p`
    margin: 0;
    
    ${theme.mediaQueries.lg} {
        position: absolute;
        right: 0;
        top: calc(50vh + 75px);
        width: 70%;
        margin: 0;
        text-align: right;
    }

    ${theme.mediaQueries.lgMax} {
        margin-top: ${theme.space.lg};
    }
`

export const LoginOptionsContainer = styled.div`
    max-width: 270px;
    text-align: center;
`

export const LoginButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

export const LoginChoiceButton = styled(Link)<LoginButtonProps>(
  ({ variant }) => css`
    height: 40px;
    line-height: 40px;
    padding: 0 ${theme.space.lg};
    text-decoration: none;
    border-radius: 10px;
    border: 1px solid #18A0FB;
    background-color: ${variant === 'blue' ? '#18A0FB' : '#FFF'};
    color: ${variant === 'blue' ? '#FFF' : '#18A0FB'};
  `,
)

export const LoginLanguageButton = styled.button<{active: boolean}>(
  ({ active }) => css`
    background: none;
    border: none;
    color: ${theme.colors.blueUI};
    cursor: pointer;
    text-decoration: underline;
    padding: 0;
    
    &:first-child {
        border-right: 1px solid ${theme.colors.greyLight};
        padding-right: ${theme.space.sm};
        margin-right: ${theme.space.sm};
    }

    img {
        border: 2px solid ${active ? theme.colors.blueUI : 'transparent'};
        border-radius: 50%;
        overflow: hidden;
        width: 25px;
        height: 25px;
        display: inline-block;
    }
`,
)

export const CenteredContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 520px;
    margin: 0 auto;

    & > p {
        text-transform: uppercase;
        font-size: 1.2em;
        text-align: center;
        margin: 0 0 ${theme.space.md};
    }
`

export const LoginLogoContainer = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 0 ${theme.space.lg};
    text-align: center;

    img {
        width: 100%;
    }
`

export const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: ${theme.space.lg};
`

export const LoginContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: ${theme.space.lg};
`

export const FormsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: ${theme.space.lg};
`

export const FormsChoice = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: ${theme.space.lg};
`
export const SimpleBox = styled.div<{
    blueBG?: boolean, inModal?: boolean, hasMarginBottom?: boolean}>(
      ({ blueBG, inModal, hasMarginBottom }) => css`
    padding: ${theme.space.md};
    color: ${blueBG ? '#FFF !imporant' : '#2C2C2C'};
    background-color: ${blueBG ? theme.colors.blueBGUI : '#FFF'};
    border-radius: 10px;
    white-space: pre-wrap;
    text-align: left;
    min-width: ${inModal ? '280px' : 'auto'};
    width: ${inModal ? '800px' : 'auto'};
    max-height: ${inModal ? 'calc(100vh - 100px)' : 'auto'};
    overflow-y: ${inModal ? 'auto' : 'visible'};
    position: ${inModal ? 'relative' : 'static'};
    margin-bottom: ${hasMarginBottom ? theme.space.md : '0'};

    ${FieldItemRow} {
        margin: 0;
        background: ${theme.colors.blueBGUILight};
    }
`,
    )

export const SimpleGrid = styled.div<{columnsSplit?: string}>(
  ({ columnsSplit }) => css`

    display: grid;
    grid-template-columns: ${columnsSplit || '1fr 1fr 1fr 1fr'};
    column-gap: ${theme.space.md};
    row-gap: ${theme.space.md};
    grid-template-rows: auto;

    ${theme.mediaQueries.xxlMax} {
        grid-template-columns: 1fr 1fr 1fr;
    }

    ${theme.mediaQueries.lgMax} {
        grid-template-columns: 1fr;
    }
    
    ${theme.mediaQueries.mdMax} {
        grid-template-columns: 1fr 1fr;
    }

    ${theme.mediaQueries.smMax} {
        grid-template-columns: 1fr;
    }
`,
)

export const OverlayContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.5);
    text-align: center;
    padding: 0 ${theme.space.lg}

    ${SimpleBox} {
        position: relative;
        max-width: 800px;
    }
`

export const CloseOverlayButton = styled.button`
    position: absolute;
    z-index: 9999;
    top: ${theme.space.md};
    right: ${theme.space.md};
    width: 40px;
    height: 40px;
    background: ${theme.colors.blueBGUI};
    border-radius: 50%;
    border: none;
    color: #FFF;
    cursor: pointer;
    padding: ${theme.space.sm};
    font-size: 1.5em;
`
