import styled, { css } from 'styled-components'
import { theme } from '../../theme/theme'

export const TopBar = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  height: 56px;
  padding: ${theme.space.sm} 0;

  .mobileLogo {
    width: 170px;

    ${theme.mediaQueries.mdMin} {
      display: none;
    }
  }
`

export const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  svg {
    margin-left: ${theme.space.sm};
  }

  ${theme.mediaQueries.mdMax} {
    .name {display: none;}
  }
`

export const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: ${theme.space.md};
  color: #FFF;

  ${theme.mediaQueries.mdMax} {
    margin-left: ${theme.space.sm};
  }

  ${theme.mediaQueries.md} {
    display: none;
  }
  
  &:focus {
    outline: none;
  }
  
  div {
    width: 2rem;
    height: 0.25rem;
    background: #000;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }
`

export const LanguageSwitchMenuHolder = styled.div`
  position: relative;
  margin-left: ${theme.space.sm};
  line-height: 0;
  font-size: 0;

  & > button {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }

  img {
    border-radius: 50%;
    overflow: hidden;
    width: 25px;
    height: 25px;
    display: block;
  }
`

export const LanguageMenu = styled.ul<{open: boolean}>(
  ({ open }) => css`
    display: ${open ? 'block' : 'none'};
    list-style: none;
    position: absolute;
    top 130%;
    right: 0;
    width: 120px;
    background: #FFF;
    border-radius: 5px;
    padding: 0;
    margin: 0;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    z-index: 1000;
    text-align: right;

    li {
      position: relative;
      overflow: hidden;
      
      &:not(:last-child) {
        border-bottom: 1px solid ${theme.colors.greyLight};
      }

      &:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      &:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    button {
      background: transparent;
      border: none;
      cursor: pointer;
      padding: ${theme.space.sm};
      color: #000;
      white-space: nowrap;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;

      &:hover, &:focus {
        outline: none;
        background: lightgray;
      }

      img {
        border: 1px solid gray;
        width: 20px;
        height: 20px;
        margin-left: ${theme.space.sm};
        display: inline-block;
      }
    }
  `,
)
