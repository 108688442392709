import React, {
  Dispatch, SetStateAction, useContext, useEffect, useRef, useState, memo,
} from 'react'
import { useTranslation } from 'react-i18next'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowDownShortWide, faArrowDownWideShort, faSearch, faRemove,
} from '@fortawesome/free-solid-svg-icons'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import {
  FiltersControls,
  FiltersHolder, PageHeadingContainer, UnstyledButton,
} from './PageHeading.styles'
import {
  compareByNumbers, compareByName, compareByTruthy, compareDates,
} from '../../utils/compareFunctions'
import { GlobalStateContext } from '../../providers/GlobalStateProvider'

interface PageHeadingProps {
  title: string
  sortingData?: any[] | undefined
  searchByName?: boolean
  nameKey?: string
  uidKey?: string
  areaKey?: string
  hasMixKey?: string
  dateKey?: string
  softScoringKey?: string
  agromonDataKey?: string
  setSortedData: Dispatch<SetStateAction<any[] | undefined>>
  sortedData?: any[] | undefined
  initialSortingOrder?: string
  cacheDataKey?: string
}

export const PageHeading: React.FC<PageHeadingProps> = (
  {
    title,
    sortingData,
    searchByName,
    nameKey,
    uidKey,
    areaKey,
    hasMixKey,
    dateKey,
    softScoringKey,
    agromonDataKey,
    setSortedData,
    sortedData,
    initialSortingOrder = 'ascending',
    cacheDataKey,
  },
) => {
  const {
    filteredDataParams, setFilteredDataParams,
  } = useContext(GlobalStateContext)
  const [sortingOrder, setSortingOrder] = useState(
    filteredDataParams[`${cacheDataKey}`]?.sortingOrder || initialSortingOrder,
  )
  const [sortingParam, setSortingParam] = useState(
    filteredDataParams[`${cacheDataKey}`]?.sortingParam || '',
  )
  const [searchedByText, setSearchedByText] = useState(
    filteredDataParams[`${cacheDataKey}`]?.searchedByText || '',
  )
  const [searchInputImmidiateValue, setSearchInputImmidiateValue] = useState(filteredDataParams[`${cacheDataKey}`]?.searchedByText || '')
  const { t } = useTranslation()

  const firstRun = useRef(true)

  const handleSorting = (event: any) => {
    const sortParam = event.target.value as string
    setSortingParam(sortParam)
  }

  const sortingFunctions = (dataToSort: any) => {
    const dataSource = dataToSort || sortedData || sortingData!

    if (sortingParam.indexOf('softScoring') > -1 && softScoringKey) {
      const param: string = sortingParam.split('_')[1]
      const sortedBySoftScoring = [...dataSource]
      sortedBySoftScoring.sort((a: any, b: any) => compareByNumbers(a, b, `${softScoringKey}.${param}Score`, sortingOrder))
      setSortedData(sortedBySoftScoring)
      // console.log('nameKey', softScoringKey,
      // param, sortingData[0][softScoringKey as any][`${param}Score` as any])
    }

    if (sortingParam.indexOf('agroData') > -1 && agromonDataKey) {
      const param: string = sortingParam.split('_')[1]
      const sortedByAgroData = [...dataSource]
      sortedByAgroData.sort((a: any, b: any) => compareByNumbers(a, b, `${agromonDataKey}.${param}`, sortingOrder))
      setSortedData(sortedByAgroData)
      // console.log('nameKey', softScoringKey,
      // param, sortingData[0][softScoringKey as any][`${param}Score` as any])
    }

    if (sortingParam === 'alphabetical') {
      const sortedByName = [...dataSource]
      sortedByName.sort((a: any, b: any) => compareByName(a, b, sortingOrder, nameKey!))
      setSortedData(sortedByName)
    }

    if (sortingParam === 'area') {
      const sortedByArea = [...dataSource]
      sortedByArea.sort((a: any, b: any) => compareByNumbers(a, b, areaKey, sortingOrder))
      setSortedData(sortedByArea)
    }

    if (sortingParam === 'hasMix') {
      let sortedByHasMix = [...dataSource]
      sortedByHasMix = compareByTruthy(sortedByHasMix, hasMixKey, sortingOrder)
      setSortedData(sortedByHasMix)
    }

    if (sortingParam === 'date') {
      const sortedByDate = [...dataSource]
      sortedByDate.sort((a: any, b: any) => compareDates(a[dateKey!], b[dateKey!], sortingOrder))
      setSortedData(sortedByDate)
    }
  }

  const filterByString = (searchParam: string) => {
    const lowerCaseSearchParam = searchParam.toLowerCase()
    const filteredByString = sortingData!.filter(
      (item) => {
        if (
          item[nameKey!].toLowerCase().indexOf(lowerCaseSearchParam) > -1
          || (
            item[uidKey!] !== null
            && item[uidKey!].toLowerCase().indexOf(lowerCaseSearchParam) > -1
          )
        ) {
          return true
        }
        return false
      },
    )

    setSortedData(filteredByString)
    setSearchedByText(searchParam)

    return filteredByString
  }

  let searchDebounce: any
  const filterByStringDebounced = (searchParam: string) => {
    setSearchInputImmidiateValue(searchParam)
    clearTimeout(searchDebounce)

    searchDebounce = setTimeout(() => {
      filterByString(searchParam)
    }, 700)
  }

  useEffect(() => {
    if (sortingData && sortingData.length > 0) {
      let filteredByName = null
      if (searchedByText !== '') {
        filteredByName = filterByString(searchedByText)
      }
      sortingFunctions(filteredByName)
    }
  }, [JSON.stringify(sortingData)])

  useEffect(() => {
    if (firstRun.current || !sortingData) {
      firstRun.current = false
      return
    }
    sortingFunctions(null)

    if (cacheDataKey) {
      setFilteredDataParams((prev: any) => ({
        ...prev,
        [`${cacheDataKey}`]: {
          sortingParam, sortingOrder, searchedByText,
        },
      }))
    }
  }, [sortingParam, sortingOrder, searchedByText])

  const isDataFiltered = () => {
    if (filteredDataParams[`${cacheDataKey}`] === undefined) return false

    if (filteredDataParams[`${cacheDataKey}`]?.sortingParam !== ''
      || filteredDataParams[`${cacheDataKey}`]?.sortingOrder !== 'ascending'
      || filteredDataParams[`${cacheDataKey}`]?.searchedByText !== '') {
      return true
    }
    return false
  }

  const clearDataFilters = () => {
    setSortingParam('')
    setSortingOrder('ascending')
    setSearchedByText('')
    setSearchInputImmidiateValue('')
    setSortedData(sortingData)
  }

  const SortingIcon = () => {
    if (sortingOrder === 'ascending') {
      return <FontAwesomeIcon size="2x" icon={faArrowDownShortWide} />
    }
    return <FontAwesomeIcon size="2x" icon={faArrowDownWideShort} />
  }

  return (
    <PageHeadingContainer id="pageHeadingContainer">
      <h1>{title}</h1>
      <FiltersHolder>
        <FiltersControls>
          {searchByName && (
          <TextField
            type="text"
            label={t('sorting.searchByNameOrUID')}
            variant="outlined"
            onChange={(e) => filterByStringDebounced(e.target.value as string)}
            margin="none"
            size="small"
            fullWidth
            value={searchInputImmidiateValue}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FontAwesomeIcon size="1x" color="#FFF" icon={faSearch} />
                </InputAdornment>
              ),
            }}
          />
          )}
          {sortingData && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormControl fullWidth size="small" style={{ minWidth: '150px' }}>
              <InputLabel id="filters-label">{t('sorting.sortBy')}</InputLabel>
              <Select
                labelId="filters-label"
                label={t('sorting.sortBy')}
                value={sortingParam}
                onChange={handleSorting}
                size="small"
              >
                {nameKey && <MenuItem value="alphabetical">{t('sorting.alphabetically')}</MenuItem>}
                {areaKey && <MenuItem value="area">{t('sorting.area')}</MenuItem>}
                {hasMixKey && <MenuItem value="hasMix">{t('sorting.hasMix')}</MenuItem>}
                {dateKey && <MenuItem value="date">{t('sorting.date_created')}</MenuItem>}
                {softScoringKey && <MenuItem value="softScoring_chem">{t('soilAnalysis.chemicalHealth')}</MenuItem>}
                {softScoringKey && <MenuItem value="softScoring_phys">{t('soilAnalysis.physicalHealth')}</MenuItem>}
                {softScoringKey && <MenuItem value="softScoring_bio">{t('soilAnalysis.bioHealth')}</MenuItem>}
                {agromonDataKey && <MenuItem value="agroData_min">{t('sorting.ndviMin')}</MenuItem>}
                {agromonDataKey && <MenuItem value="agroData_median">{t('sorting.ndviMed')}</MenuItem>}
                {agromonDataKey && <MenuItem value="agroData_max">{t('sorting.ndviMax')}</MenuItem>}
              </Select>
            </FormControl>
            <UnstyledButton
              type="button"
              aria-label={sortingOrder}
              onClick={() => setSortingOrder((prev: any) => (prev === 'ascending' ? 'descending' : 'ascending'))}
            ><SortingIcon />
            </UnstyledButton>
          </div>
          )}

        </FiltersControls>
        {isDataFiltered() && (
        <UnstyledButton className="clearFilters" type="button" onClick={clearDataFilters}>
          <span>{t('general.clearFilters')}</span> <FontAwesomeIcon size="2x" icon={faRemove} />
        </UnstyledButton>
        )}
      </FiltersHolder>
    </PageHeadingContainer>
  )
}

// eslint-disable-next-line import/no-default-export
export default memo(PageHeading)
